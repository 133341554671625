<template>
  <div class="form-payment">
    <ModalHeader
      :left-text="''"
      :middle-text="'Pilih metode pembayaran'"
      :left-function="directPage"
      :is-rigt-column="true"
      :right-function="nextPage"
      :is-disabled="disabledNextPage"
      :right-text="'Selanjutnya'"
    />
    <div class="text-center mt-5 pt-5">
      <h6 class="text-dark text-darken-4 fw-bold-800 mb-1">Jumlah Transaksi</h6>
      <h5 class="size24 text-dark fw-bold-800 mb-1">
        {{ parseFloat(totalTransaksi || $route.query.total) | formatAmount }}
      </h5>
      <h6
        v-if="$route.query.bonus > 0"
        class="text-dark text-darken-4 size12 fw-bold-800"
      >
        Termasuk potongan diskon
        {{ parseFloat($route.query.bonus) | formatAmount }}
      </h6>
    </div>
    <b-container>
      <keep-alive>
        <FormLengkapiAlamat
          :class-name="'bg-transparent'"
          :container-class-name="'mt-lg-1 pt-lg-1'"
          :hide-button-submit="true"
          @payload="getPayload"
          @additionalCosts="getAdditionalCosts"
          @receiverNameChanged="onReceiverNameChanged"
          @receiverPhoneNumberChanged="onReceiverPhoneNumberChanged"
          @receiverAddressChanged="onReceiverAddressChanged"
          @deliveryPickupChanged="updateDeliveryPickup"
        />
      </keep-alive>
    </b-container>
    <app-tour :name-tour="'cashierTour6'" :steps="steps6" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BImg,
  VBToggle,
  BFormCheckbox,
} from "bootstrap-vue";
import AppTour from "@core/components/app-tour/AppTour.vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";

import FormLengkapiAlamat from "@/components/Payment/FormLengkapiAlamat.vue";

import InputAmount from "@/components/Cashier/Modal/InputAmount.vue";
import ModalHeader from "@/components/ModalHeader.vue";

export default {
  title() {
    return "Pembayaran";
  },
  components: {
    BContainer,
    BCard,
    BImg,
    BRow,
    BCol,
    InputAmount,
    ModalHeader,
    AppTour,
    FormLengkapiAlamat,
    BFormCheckbox,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  computed: {
    ...mapState({
      formAdditionalCost: (state) => state.cart.formAdditionalCost,
      totalTransaksi: (state) => state.cart.totalTransaksi,
      customerUuid: (state) => state.customer.customer.uuid,
    }),
    disabledNextPage() {
      const hasReceiverName = !!this.receiverName || !!this.initialReceiverName;
      const hasReceiverPhoneNumber =
        !!this.receiverPhoneNumber || !!this.initialReceiverPhoneNumber;
      const hasReceiverAddress =
        !!this.receiverAddress || !!this.initialReceiverAddress;

      if (this.delivery_pickup == "delivery") {
        return (
          (this.invoice_status != 1 && this.invoice_status != 0) ||
          !hasReceiverName ||
          !hasReceiverPhoneNumber ||
          !hasReceiverAddress
        );
      } else {
        return this.invoice_status != 1 && this.invoice_status != 0;
      }
      // return (this.invoice_status != 1 && this.invoice_status != 0 || !hasReceiverName)
    },
  },
  data() {
    return {
      total: localStorage.getItem("cash_total_payment"),
      is_cash: false,
      steps6: [
        {
          target: "#tour-choose-payment-0",
          content: `
                    <div class="text-white size16 mb-1">Pilih Metode Pembayaran</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Pilih salah satu metode pembayaran yang akan dilakukan.</div`,
          params: {
            placement: "top-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
      ],
      payments: [
        {
          name: "Tunai",
          value: "tunai",
          image: "tunai",
          class: "image-box box-tempo",
        },
        {
          name: "Transfer Bank",
          value: "transfer",
          image: "transfer",
          class: "image-box box-transfer",
        },
        {
          name: "Kredit atau Debit",
          value: "card",
          image: "kredit",
          class: "image-box box-kredit",
        },
        {
          name: "Giro",
          value: "giro",
          image: "giro",
          class: "image-box box-giro",
        },
        {
          name: "Deposit",
          value: "wallet",
          image: "deposit",
          class: "image-box box-tunai",
        },
        {
          name: "Piutang",
          value: "piutang",
          image: "piutang",
          class: "image-box box-piutang",
        },
      ],
      stepPayment: 1,
      userData: JSON.parse(localStorage.getItem("userData")),
      customer_uuid: localStorage.getItem("customer_uuid"),
      formPayload: {},
      additionalCost: [],
      invoice_status: 0,
      is_full_payment: 1,
      amount_dp: "",
      receiverName: "",
      initialReceiverName: "",
      receiverPhoneNumber: "",
      initialReceiverPhoneNumber: "",
      receiverAddress: "",
      initialReceiverAddress: "",
      delivery_pickup: "",
    };
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (!this.userData.finish_tutorial) {
      this.$tours.cashierTour6.start();
    }
    if (!this.$route.query.deliverypickup) {
      localStorage.setItem("cash_total_payment", this.$route.query.total);
    }
  },
  mounted() {
    const isFullPayment = JSON.parse(localStorage.getItem("is_full_payment"));
    const invoiceStatus = JSON.parse(localStorage.getItem("invoice_status"));
    this.is_full_payment = isFullPayment ? 1 : isFullPayment == null ? 1 : 0;
    this.invoice_status = invoiceStatus;
    this.formPayload.invoice_status = invoiceStatus;
    this.stepPayment = this.$route.query.s || 1;
    this.amount = this.$route.query.amount || "";
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "payment.split") {
      localStorage.removeItem("invoice_status");
      localStorage.removeItem("payloadDelivery");
      localStorage.removeItem("delivery_pickup");
      localStorage.removeItem("payloadTransaksi");
      localStorage.removeItem("poSO");
    }

    next();
  },
  methods: {
    ...mapActions("cart", ["addAddtionalCost"]),
    getAdditionalCosts(payload) {
      this.additionalCost = payload;
    },
    getPayload(payload) {
      const payloadTransaksi = JSON.stringify(payload);
      localStorage.setItem("payloadTransaksi", payloadTransaksi);
      this.formPayload = payload;
      this.invoice_status = payload.invoice_status;
    },
    nextPage() {
      const { date, delivery_pickup, po_so_number, seller_uuid } =
        this.formPayload;
      const { is_full_payment, payments, data, bonus, total } =
        this.$route.query;

      this.$router.push({
        name: "payment.split",
        query: {
          date,
          bonus,
          total,
          deliverypickup: delivery_pickup,
          po_so_number,
          type_payment: is_full_payment,
          payments,
          data,
          seller: seller_uuid,
        },
      });
    },
    directPage() {
      if (this.stepPayment == 1) {
        this.$router.push({
          name: "pos.index",
          query: { uuid: this.$route.query.uuid },
        });
      }
      if (this.stepPayment == 2) {
        this.stepPayment = 1;
        // this.$router.push({
        //   name: "payment.index",
        //   query: {
        //     bonus: this.$route.query.bonus,
        //     total: this.$route.query.total,
        //   },
        // });
        history.back();
      }
    },
    onReceiverNameChanged(newValue) {
      this.receiverName = newValue;
    },
    onReceiverNameChanged(newValue) {
      this.initialReceiverName = newValue;
    },
    onReceiverPhoneNumberChanged(newValue) {
      this.receiverPhoneNumber = newValue;
    },
    onReceiverPhoneNumberChanged(newValue) {
      this.initialReceiverPhoneNumber = newValue;
    },
    onReceiverAddressChanged(newValue) {
      this.receiverAddress = newValue;
    },
    onReceiverAddressChanged(newValue) {
      this.initialReceiverAddress = newValue;
    },
    updateDeliveryPickup(newValue) {
      this.delivery_pickup = newValue;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";


.split--wrapper {
  .custom-control-label {
    margin-bottom: 0 !important;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(1.2rem);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #52cec2;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #2b324f;
    background-color: #2b324f;
  }

  .custom-switch .custom-control-label::after {
    left: calc(9.5rem + 2px);
  }

  .custom-switch .custom-control-label::after {
    width: 1.5rem;
    height: 1.5rem;
  }

  .custom-switch .custom-control-label::before {
    border: none;
    background-color: #2b324f;
    box-shadow: none !important;
    cursor: pointer;
  }

  .custom-switch .custom-control-label:after {
    background-color: #7a7f94;
    cursor: pointer;
  }

  .custom-switch .custom-control-label::before {
    left: 9.5rem;
  }

  .custom-switch .custom-control-label::before {
    height: 2rem;
    transition: opacity 0.25s ease, background-color 0.1s ease;
    user-select: none;
    top: 0;
  }

  .custom-switch .custom-control-label::before {
    width: 3rem;
  }

  .custom-switch .custom-control-label {
    padding-left: unset;
  }
}

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}

.form-payment {
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
</style>
